<template>
  <b-card
    class="statement-edit-wrapper"
  >
    <!-- form -->
    <b-form id="statementForm" class="edit-form mt-2">
      <b-row>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="对账单编号"
            label-for="statement_no"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="statement_no"
              size="sm"
              v-model="statement.statement_no"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="终端客户ID"
            label-for="channel_id"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="channel_id"
              size="sm"
              v-model="statement.channel_id"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="终端客户"
            label-for="channel_name"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="channel_name"
              size="sm"
              v-model="statement.channel_name"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="仓库ID"
            label-for="warehouse_id"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="warehouse_id"
              size="sm"
              v-model="statement.warehouse_id"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="仓库名称"
            label-for="warehouse_name"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="warehouse_name"
              size="sm"
              v-model="statement.warehouse_name"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="应收金额"
            label-for="receivable_total"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="receivable_total"
              size="sm"
              v-model="statement.receivable_total"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="已收金额"
            label-for="received_tatal"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="received_tatal"
              size="sm"
              v-model="statement.received_tatal"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="其他费用"
            label-for="other_cost"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="other_cost"
              size="sm"
              v-model="statement.other_cost"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="应收余额"
            label-for="remain_total"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="remain_total"
              size="sm"
              v-model="statement.remain_total"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="到账天数"
            label-for="statement_over"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="statement_over"
              size="sm"
              v-model="statement.statement_over"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="预计到账日期"
            label-for="statement_over_day"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="statement_over_day"
              size="sm"
              v-model="statement.statement_over_day"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="状态"
            label-for="state"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="state"
              size="sm"
              v-model="statement.state"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="结算日期"
            label-for="add_time"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="add_time"
              size="sm"
              v-model="statement.add_time"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="创建人"
            label-for="creator"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="creator"
              size="sm"
              v-model="statement.creator"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="修改时间"
            label-for="modify_time"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="modify_time"
              size="sm"
              v-model="statement.modify_time"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="修改人"
            label-for="updator"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="updator"
              size="sm"
              v-model="statement.updator"
            />
          </b-form-group>
        </b-col>
                <b-col
          cols="12"
          class="mt-50"
        >
          <b-button
            variant="primary"
            class="mr-1"
            @click="save"
          >
            保存
          </b-button>
          <b-button
            variant="outline-secondary"
            @click="cancel"
          >
            取消
          </b-button>
        </b-col>
      </b-row>
    </b-form>
    <!--/ form -->
  </b-card>
</template>

<script>
import {
  BCard, BMedia, BAvatar, BCardText, BForm, BRow, BCol, BFormGroup, BFormInput, BImg, BFormFile, BLink, BButton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import {onUnmounted, ref} from '@vue/composition-api'
import router from "@/router";
import store from "@/store";
import statementStore from './statementStore'
// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { toTime, toDate, getCodeOptions, getCode, getCodeLabel, getCodeColor } from '@core/utils/filter'

export default {
  components: {
    BCard,
    BMedia,
    BAvatar,
    BCardText,
    BForm,
    BLink,
    BImg,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormFile,
    vSelect,
    useToast,
  },
  data() {
    return {
      id: ref(0),
      statement: ref({}),
    }
  },
  setup() {
    const toast = useToast()

    // Register module
    if (!store.hasModule('statement')) store.registerModule('statement', statementStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('statement')) store.unregisterModule('statement')
    })

    const edit = function() {
      store.dispatch('statement/edit', {id: this.id}).then(res => {
        this.statement = res.data.data
      })
    }

    const view = function() {
      store.dispatch('statement/view', {id: this.id}).then(res => {
        this.statement = res.data.data
      })
    }

    const cancel = function() {
      this.$router.go(-1)
    }

    const save = function() {
      store.dispatch('statement/save', this.statement).then(res => {
        toast.success('数据已保存!')
        this.$router.push({ name: 'apps-${classVar}-list'});
      })
    }

    return {
      edit,
      view,
      cancel,
      save,
      
      getCodeOptions,
      getCode,
      getCodeLabel,
      getCodeColor,
    }
  },
  created() {
    this.id = this.$route.query.id || 0;
    this.edit()
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>